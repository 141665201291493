import { useTeamsFx } from '@microsoft/teamsfx-react'
import { useEffect, useState } from 'react'
import { app, authentication } from '@microsoft/teams-js'
import { ApiService } from 'api/api'
import { Constants } from 'consts/constants'
import jwtDecode from 'jwt-decode'
import { CheckUserExistsRequestBody } from 'api/api.types'

export interface UserInformation {
  upn: string
  family_name: string
  given_name: string
  oid: string
  tid: string
}

export const defaultUserInformation = {
  upn: '',
  family_name: '',
  given_name: '',
  oid: '',
  tid: '',
}

const useAuth = () => {
  const { inTeams } = useTeamsFx()
  const [userName, setUserName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [userInformation, setUserInformation] = useState<UserInformation>(defaultUserInformation)
  const [error, setError] = useState<string>()
  const [checkingIsRegistered, setCheckingIsRegistered] = useState<boolean>(true)
  const [isRegistered, setRegistered] = useState<boolean>(false)

  //# SSO implementation
  useEffect(() => {
    if (inTeams) {
      authentication.getAuthToken({
        resources: [process.env.TAB_APP_URI as string],
        silent: false,
      } as authentication.AuthTokenRequestParameters).then(async (token) => {
        ApiService.ssotoken = token
        const tokenresult = await ApiService.fetchToken()
        if (!tokenresult) {
          const appcontext = await app.getContext()
          await authentication.authenticate({
            url:
              `${window.location.origin}/auth-start.html?clientId=${Constants.CLIENT_ID_API}` +
              `&scope=${Constants.SCOPES}&loginHint=${appcontext.user?.loginHint}`,
            width: 600,
            height: 535,
            isExternal: false,
          })
          await authentication.authenticate({
            url:
              `${window.location.origin}/auth-start.html?clientId=${Constants.CLIENT_ID_SSO}` +
              `&scope=${Constants.SCOPES}&loginHint=${appcontext.user?.loginHint}`,
            width: 600,
            height: 535,
            isExternal: false,
          })
          await ApiService.fetchToken()
        }

        const decoded = jwtDecode<{ [key: string]: string }>(token)
        console.log('HERE')
        console.log(decoded)
        setUserName(decoded?.name)
        setEmail(decoded?.upn)
        setUserInformation({
          upn: decoded?.upn,
          family_name: decoded?.family_name,
          given_name: decoded?.given_name,
          oid: decoded?.oid,
          tid: decoded?.tid,
        })
        app.notifySuccess()
      }).catch(message => {
        setError(message)
        app.notifyFailure({
          reason: app.FailedReason.AuthFailed,
          message,
        })
      })
    } else {
      // todo add logic for dev working independent on teams app
      if (!Constants.devToken) {
        return
      }
      const decoded = jwtDecode<{ [key: string]: string }>(Constants.devToken)
      setUserName(decoded?.name)
      setEmail(decoded?.upn || decoded.preferred_username)
      setUserInformation({
        upn: decoded?.upn || decoded.preferred_username,
        family_name: decoded?.family_name,
        given_name: decoded?.given_name,
        oid: decoded?.oid,
        tid: decoded?.tid,
      })
      app.notifySuccess()
    }
  }, [inTeams])

  //# once SSO is done we are checking if user exist or not and accordingly
  // we are navigating user to Registration or Dashboard Page
  const checkUserExists = async (body: CheckUserExistsRequestBody) => {
    await ApiService.checkUserExists(body).then((response) => {
      console.log('checkUserExists')
      console.log(response)
      setRegistered(response.exists)
      setCheckingIsRegistered(false)
      if (!response.exists) {
        //registerNewUser({ appUser: false })
      }
    }).catch(message => {
      console.log(message)
      setError(message)
      app.notifyFailure({
        reason: app.FailedReason.AuthFailed,
        message,
      })
    })
  }

  useEffect(() => {
    if(email) {
      checkUserExists({ email })
    }
  }, [email])

  return {
    userName,
    email,
    userInformation,
    error,
    checkingIsRegistered,
    isRegistered,
    checkUserExists,
  }
}

export default useAuth
