import { Loader } from '@fluentui/react-northstar'
import { BrowserRouter, Redirect, Route } from 'react-router-dom'
import { useTeamsFx } from '@microsoft/teamsfx-react'
import { TeamsFxContext } from './Context'
import { TeamsTheme } from '@fluentui/react-teams/lib/cjs/themes'
import { Provider } from '@fluentui/react-teams'

import useAuth from 'hooks/useAuth'
import './App.scss'
import { lazy, Suspense } from 'react'
import CapturePermissions from 'tabs/CaptureTab/Views/Capture/CapturePermissions'

// Lazy loading components
const Capture = lazy(() =>
  import(/* webpackChunkName: "capture" */ 'tabs/CaptureTab/Views/Capture/Capture'))
const ErrorNotification = lazy(() =>
  import(/* webpackChunkName: "error-notification" */ 'tabs/CaptureTab/Views/ErrorNotification/ErrorNotification'))
const BadConditions = lazy(() =>
  import(/* webpackChunkName: "bad-conditions" */ 'tabs/CaptureTab/Views/BadConditions/BadConditions'))
const Results = lazy(() =>
  import(/* webpackChunkName: "results" */ 'tabs/CaptureTab/Views/Results/Results'))
const Dashboard = lazy(() =>
  import(/* webpackChunkName: "dashboard" */ 'tabs/DashboardTab/Dashboard'))
const Registration = lazy(() =>
  import(/* webpackChunkName: "registration" */ 'tabs/RegistrationTab/Registration'))
const Config = lazy(() =>
  import(/* webpackChunkName: "config" */ 'tabs/ConfigTab/Config'))
const Privacy = lazy(() =>
  import(/* webpackChunkName: "privacy" */ 'tabs/PrivacyTab/Privacy'))
const TermsOfUse = lazy(() =>
  import(/* webpackChunkName: "terms-of-use" */ 'tabs/TermsOfUseTab/TermsOfUse'))
const Profile = lazy(() =>
  import(/* webpackChunkName: "terms-of-use" */ 'tabs/ProfileTab/ProfileTab'))

export default function App() {
  const { loading, theme, themeString, teamsfx } = useTeamsFx()

  const {
    userName,
    userInformation,
    // error,
    checkingIsRegistered,
    isRegistered,
  } = useAuth()

  return (
    checkingIsRegistered ?
      <TeamsFxContext.Provider value={{ theme, themeString, teamsfx, userName }}>
        <Provider themeName={TeamsTheme.Dark} lang="en-US">
          <Loader style={{ margin: 100 }} label='just a moment...' />
        </Provider>
      </TeamsFxContext.Provider> :
      <TeamsFxContext.Provider value={{ theme, themeString, teamsfx, userName, userInformation }}>
        <Provider themeName={TeamsTheme.Dark} lang="en-US">
          <BrowserRouter>
            <Route exact path="/">
              <Redirect to={!isRegistered ? '/registration' : '/dashboard'} />
            </Route>
            {loading ? (
              <Loader style={{ margin: 100 }} label={'just a moment...'} />
            ) : (
              <Suspense fallback={<Loader style={{ margin: 100 }} label='just a moment...' />}>
                <Route path="/registration" component={Registration} ></Route>
                <Route path="/capture" render={({ match: { url } }) => (
                  <>
                    <Route exact path={`${url}/`} component={Capture} />
                    <Route exact path={`${url}/permissions`} component={CapturePermissions} />
                    <Route exact path={`${url}/error`} component={ErrorNotification} />
                    <Route exact path={`${url}/bad-conditions`} component={BadConditions} />
                    <Route exact path={`${url}/results`} component={Results} />
                  </>
                )} />
                <Route path="/dashboard" render={({ match: { url } }) => (
                  <>
                    <Route exact path={`${url}/`} component={Dashboard} />
                  </>
                )} />
                <Route exact path="/privacy" component={Privacy} />
                <Route exact path="/config" component={Config} />
                <Route exact path="/termsofuse" component={TermsOfUse} />
                <Route exact path="/profile" component={Profile} />
              </Suspense>
            )}
          </BrowserRouter>
        </Provider>
      </TeamsFxContext.Provider>
  )
}
