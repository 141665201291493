/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-magic-numbers */
export const Constants = {
  HOSTED_URL: process.env.REACT_APP_NODE_ENV === 'development'?'https://vastmindzteamsapp-dev.azurewebsites.net':'https://vastmindz-teams.com/',
  CLIENT_ID_SSO: process.env.REACT_APP_NODE_ENV === 'development'? '54103c3c-c41d-447a-a5eb-fe0cb16270fb' :
    'b2bf3a84-83e2-4aa9-a5aa-e26a594fe798',
  APP_ID: process.env.REACT_APP_NODE_ENV === 'development'?'2f8c5d41-0ff5-43a4-8083-dd2d54cbd207':
    'b9782016-daf3-439c-91bd-b9403140c3ea',
  CAPTURETAB_ID: process.env.REACT_APP_NODE_ENV === 'development'?'68987a15-4736-4dc7-9b08-8823f0fc7460':
    '3d69632f-1aa9-446c-b30d-5191eb2961b5',
  HOMETAB_ID: process.env.REACT_APP_NODE_ENV === 'development'?'2f1b8993-2f5d-4598-b82e-55932bd8b2c9':
    '2f1b8993-2f5d-4598-b82e-55932bd8b2c9',
  CLIENT_ID_API: '3c92ff0e-d031-494b-8b50-f3ae46aaea43',
  SCOPES: 'api://vm-development.xyz/3c92ff0e-d031-494b-8b50-f3ae46aaea43/access_as_user',
  MICROSOFTAUTHORITY: 'https://login.microsoftonline.com/common',
  TOKENAPIBaseUrl: process.env.PUBLIC_URL,
  //TOKENAPIBaseUrl: 'https://visix-dev.xyz',
  APIBaseUrlNew: 'https://vm-development.xyz/health-dashboard/api/v1/dashboard/',
  APIBaseUrl: 'https://vm-development.xyz/health-dashboard/dashboard',
  APIBaseUrlDacadooService: 'https://vm-development.xyz/dacadoo-service',
  APIRecommMicroservice: process.env.REACT_APP_RECOMM_MICROSERVICE || 'https://recomm-microservice.azurewebsites.net',
  //https://rppg-prod.xyz/dacadoo-service/dacadoo
  BearerToken: 'Bearer',
  SummaryChartLimit: 25,
  minDateYears: 17,
  maxDateYears: 90,
  minHeight:59.5,
  maxHeight:279.5,
  minWeight:28,
  maxWeight:318,
  setBearerToken: (token: string) => {
    Constants.BearerToken = `Bearer ${token}`
  },
  monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  // todo refactor using moment
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateFormat: (d: any, format: string) => {
    const t = new Date(d)
    switch (format) {
      case 'mmm-dd':
        return Constants.monthNames[t.getMonth()] + '-' + (t.getDate() < 10 ? '0' : '') + t.getDate()
      case 'mmm-dd-yyyy':
        return Constants.monthNames[t.getMonth()] + '-' + (t.getDate() < 10 ? '0' : '') +
          t.getDate() + '-' + t.getFullYear()
      case 'yyyy-mm-dd':
        return t.getFullYear() + '-' + (((t.getMonth() + 1) < 10 ? '0' : '') +
              (t.getMonth() + 1)) + '-' + (t.getDate() < 10 ? '0' : '') + t.getDate()
      case 'mm/dd/yyyy':
        return (((t.getMonth() + 1) < 10 ? '0' : '')+(t.getMonth() + 1)) + '/' +
        ((t.getDate() < 10 ? '0' : '') + t.getDate()) + '/' + t.getFullYear()
      default:
        return Constants.monthNames[t.getMonth()] + '-' + (t.getDate() < 10 ? '0' : '') +
          t.getDate() + '-' + t.getFullYear()
    }
  },
  calculateAge: (birthday: any) => {
    const t:any = new Date(birthday)
    const ageDifMs = Date.now() - t
    const ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  },
  devToken: process.env.NODE_ENV === 'development' && localStorage.getItem('devToken'),
  // eslint-disable-next-line max-len
  supabaseToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhneWtnaHpwamdjYmtocGtxbmFuIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4MzM5NTM4NywiZXhwIjoxOTk4OTcxMzg3fQ.rGrld01j11Fzd6s_F7CL7YtcOFLlueAhsKjsNKwURoU',
  supabaseTable: 'Visix',
  supabaseUrl: 'https://xgykghzpjgcbkhpkqnan.supabase.co/',
  welnessScore: [{
    min: -1,
    max: 0,
    definition: 'Insufficient data',
    type: 'undetermined',
    label: 'Insufficient data',
    title: '',
    text: 'Come back tomorrow after collecting additional data to unlock your daily readiness guidance!',
  }, {
    min: 1,
    max: 3,
    definition: 'Low',
    type: 'low',
    label: 'Low',
    title: 'Prioritize recovery',
    // eslint-disable-next-line max-len
    text: 'Your sympathetic activity is overly elevated and your body is under stress. Focus on hydration, supportive nutrition and a solid night\'s sleep.',
  }, {
    min: 4,
    max: 6,
    definition: 'Medium',
    type: 'medium',
    label: 'Medium',
    title: 'Take it easy',
    // eslint-disable-next-line max-len
    text: 'Your HRV is somewhat elevated compared to your baseline. Focus on hydration to replenish energy levels along with supportive nutrition.',
  }, {
    min: 7,
    max: 10,
    definition: 'Optimal',
    type: 'optimal',
    label: 'Optimal',
    title: 'Bring it on!',
    // eslint-disable-next-line max-len
    text: 'Your wellness score indicates that you are well rested and recovered and ready for another day of intense work.',
  }, {
    min: null,
    max: null,
    definition: 'Calculating',
    type: 'calculating',
    label: 'Calculating',
    title: 'Ready soon',
    // eslint-disable-next-line max-len
    text: 'Our wellness score needs at least two readings on two different days. Come back for a reading tomorrow and you\'ll be able to track your wellness score.',
  }],
}