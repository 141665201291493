import axios from 'axios'
import {
  Constants,
} from 'consts/constants'
import {
  CheckUserExistsRequestBody,
  CheckUserExistsResponse,
  ConversationResponse,
  DashboardUser,
  RegisterNewUserRequestBody,
  SaveConversationRequestBody,
  saveMeasurementRequestBody,
  SearchConversationRequestBody,
  SearchMeasurementRequestBody,
  SearchMeasurementResponse,
  DacadooResponse,
  DacadooRequestBody,
  InsightsResponse,
  VStateRequestBody,
  VStateResponse,
  updateProfileRequestBody,
  UpdateProfileResponse,
  UserProfile,
  DeleteProfileResponse,
  getMeasurementRequestBody,
  SaveDataToSupabaseRequest,
} from './api.types'
import { createClient } from '@supabase/supabase-js'

export class ApiServiceClass {
  public ssotoken = ''
  private supabaseToken = Constants.supabaseToken || ''
  private supabaseTable = Constants.supabaseTable || ''
  private supabaseUrl = Constants.supabaseUrl || ''
  private token = Constants.devToken || ''

  public async checkUserExists(body: CheckUserExistsRequestBody): Promise<CheckUserExistsResponse> {
    /*
    {
        "dateOfBirth":"2002-04-19",
        "weight": 76,
        "height": 170,
        "appUser": true
    }
    */
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrl}/check_user_exists`
    const res = await axios.post<CheckUserExistsResponse>(request, body, config)
    return res.data
  }

  public async registerNewUser(body: RegisterNewUserRequestBody): Promise<DashboardUser> {
    /*
    {
        "dateOfBirth":"2002-04-19",
        "weight": 76,
        "height": 170,
        "appUser": true
    }
    */
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrlNew}profile`
    console.log(config)
    try {
      const res = await axios.post<DashboardUser>(request, body, config)
      return res.data
    }catch(error) {
      console.log(error)
      return {success:false, height:0, id:0, name: '', weight: 0}
    }
  }

  public async saveConversation(body: SaveConversationRequestBody): Promise<ConversationResponse> {
    /*
    {
        "startDateTime": "2022-04-06T03:45:42.01",
        "endDateTime": "2022-04-06T03:47:42.01",
        "conversation": "conversation_json_text"
    }
    */
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrl}/conversation`
    const res = await axios.post<ConversationResponse>(request, body, config)
    return res.data
  }

  public async searchConversation(body: SearchConversationRequestBody): Promise<ConversationResponse> {
    /*
    {
        "startDate": null,
        "endDate":null
    }
    */
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrl}/conversation/search`
    const res = await axios.post<ConversationResponse>(request, body, config)
    return res.data
  }

  public async saveMeasurement(body: saveMeasurementRequestBody): Promise<SearchMeasurementResponse> {
    /*
    {
        "startDateTime": "2022-04-06T03:45:42.01",
        "endDateTime": "2022-04-06T03:47:42.01",
        "avgBpm":10.99,
        "avgRespirationRate": 11.45,
        "avgHRV": 12.05,
        "stress": 10,
        "sessionDuration": 200
        "rr": []
    }
    */
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrlNew}/health-measurement`
    const res = await axios.post<SearchMeasurementResponse>(request, body, config)
    return res.data
  }
  //for profile update
  public async getMyProfile(): Promise<UserProfile> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrlNew}profile`
    const res = await axios.get<UserProfile>(request, config)
    return res.data
  }

  public async updateProfile(body: updateProfileRequestBody): Promise<UpdateProfileResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request =`${Constants.APIBaseUrlNew}profile`
    const res = await axios.patch<UpdateProfileResponse>(request, body, config)
    return res.data
  }
  //delete profile
  public async deleteProfile(): Promise<DeleteProfileResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request =`${Constants.APIBaseUrlNew}profile`
    const res = await axios.delete<DeleteProfileResponse>(request, config)
    return res.data
  }

  public async getMeasurement(): Promise<getMeasurementRequestBody[]> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrlNew}/health-measurement`
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const res = await axios.get<getMeasurementRequestBody[]>(request, config)
    console.log(res)
    return res.data
  }

  public async dacadoo(body: DacadooRequestBody): Promise<DacadooResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    //const request = `${Constants.APIBaseUrlDacadooService}/dacadoo`
    const request = 'https://rppg-prod.xyz/dacadoo-service/dacadoo'
    const res = await axios.post<DacadooResponse>(request, body, config)
    return res.data
  }

  public async searchMeasurement(body: SearchMeasurementRequestBody): Promise<SearchMeasurementResponse[]> {
    /*
    {
        "startDate": null,
        "endDate":null
    }
    */
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrl}/measurement/search`
    const res = await axios.post<SearchMeasurementResponse[]>(request, body, config)
    return res.data
  }

  public async getMyInformation(): Promise<DashboardUser> {
    const config = {
      headers: {
        Authorization: `Bearer ${this.token}`,
      },
    }
    const request = `${Constants.APIBaseUrlNew}/profile`
    const res = await axios.get<DashboardUser>(request, config)
    return res.data
  }

  public async fetchToken(): Promise< boolean > {
    const request = `${Constants.TOKENAPIBaseUrl}/api/Token?idToken=${this.ssotoken}`
    const response = await axios.post(request)
    console.log('fetchToken')
    console.log(response)
    if (response.data.indexOf('AADSTS65001') > -1) {
      return false
    } else {
      this.token = response.data
      return true
    }
  }

  public async fetchInsights(searchMeasurementData: getMeasurementRequestBody[], userProfile: UserProfile) {
    const request = `${Constants.APIRecommMicroservice}/insights`
    const requestBody = []
    // eslint-disable-next-line
    searchMeasurementData && searchMeasurementData.map((item) => {
      requestBody.push(item)
    })
    requestBody.push(userProfile)
    const response = await axios.post<InsightsResponse>(request, requestBody)
    return response.data
  }

  public async fetchVstate(body: VStateRequestBody) {
    const request = `${Constants.APIRecommMicroservice}/vstate`
    const response = await axios.post<VStateResponse>(request, body)
    return response.data
  }

  public async saveDataToNotionDb(values: SaveDataToSupabaseRequest): Promise<void> {
    const supabase = createClient(this.supabaseUrl, this.supabaseToken)

    await supabase
      .from(this.supabaseTable)
      .insert({
        tenant_id: values.tenantId,
        timestamp: values.date,
        snr: values.snr,
      })
  }
}

export const ApiService = new ApiServiceClass()
