import { TeamsFx } from '@microsoft/teamsfx'
import { createContext } from 'react'
import { ThemePrepared } from '@fluentui/react-northstar'
import { UserInformation } from 'hooks/useAuth'

export const TeamsFxContext = createContext<{
  theme?: ThemePrepared,
  themeString: string,
  teamsfx?: TeamsFx,
  userName?: string,
  userInformation?: UserInformation
}>({
  theme: undefined,
  themeString: '',
  teamsfx: undefined,
  userName: '',
  userInformation: {
    upn:'',
    family_name:'',
    given_name:'',
    oid:'',
    tid: '',
  },
})
